import React from 'react'
import {Typography} from '@mui/material'

const Whitepapper = () => {
    return <>
        <Typography variant={'h3'}>Whitepapper</Typography>
        <Typography>
            В основе DAO Dao лежит смарт контракт на блокчейне WAX для создания микроDAO (mDAO). Каждое такое mDAO может
            выпускать долевые и управленческие токены, проводить голосования и распоряжаться накопившимися средствами.
        </Typography>
        <Typography variant={'h5'}>Токены mDAO</Typography>
        <Typography>
            <u>Управленческие токены</u> используются для голосований. Первый токен выдается аккаунту создавшему mDAO,
            следующие выдаются путем голосования управляющими токенами, либо выставлением на продажу после такого же
            голосования.
        </Typography>
        <Typography>
            В настройках токенов можно установить возможность его передачи на другой кошелек, а также максимальное
            количество таких токенов на одном аккаунте.
        </Typography>
        <Typography>
            <u>Долевые токены</u> участвуют в распределении средств mDAO. Выпускаются после проведения голосования.
            Возможные варианты выпуска:
            <ul>
                <li>Оплата определенных работ для mDAO. Сразу после успешного голосования передаются на указанный
                    аккаунт.
                </li>
                <li>Сбор средств для mDAO. После голосования создаются токены и выставляются на продажу по указанной
                    цене. Все полученные средства идут в копилку mDAO.
                </li>
            </ul>
        </Typography>
        <Typography variant={'h5'}>Голосования</Typography>
        <Typography>
            В голосованиях принимают участие только держатели управленческих токенов.
        </Typography>
        <Typography>
            Виды голосований:
            <ul>
                <li>Выпуск управленческих токенов и передача их на указанный аккаунт.</li>
                <li>Выпуск управленческих токенов и выставление их на продажу.</li>
                <li>Выпуск долевых токенов для оплаты работы.</li>
                <li>Выпуск долевых токенов для сбора средств.</li>
                <li>Оплата чего-либо из средств mDAO.</li>
                <li>Распределение указанной суммы пропорционально среди держателей долевых токенов.</li>
                <li>Обратный выкуп (из средств mDAO) долевых токенов и их сжигание.</li>
                <li>Голосование по любому вопросу, в контракте просто сохраняется результат голосования.</li>
            </ul>
        </Typography>
        <Typography variant={'h5'}>Пополнение средств mDAO</Typography>
        <Typography>
            Кроме вышеуказанных способов (продажа токенов) возможно пополнение средств путем прямого перевода на
            контракт. Например, у mDAO есть свой контракт, тогда в нем можно настроить вывод сразу в копилку.
        </Typography>
        <Typography variant={'h3'} sx={{pt: 5}}>Токеномика DAO Dao</Typography>
        <Typography>
            Для управления самим DAO Dao будут выпущены отдельные токены. Максимальное количество: 10М. Изначально все
            токены будут принадлежать команде. Для первого этапа разработки будет продано 10% от общего количества
            токенов, по цене в $0.01.
        </Typography>
        <Typography variant={'h3'} sx={{pt: 5}}>Пополнение средств DAO Dao</Typography>
        <Typography variant={'h5'}>Комисии</Typography>
        <Typography>
            <ul>
                <li>Регистрация 3-х символьного имени mDAO - 15000 WAX</li>
                <li>Регистрация 4-х символьного имени mDAO - 750 WAX</li>
                <li>Регистрация 5-ти и более символьного имени mDAO - 15 WAX</li>
                <li>Создание голосования - 1 WAX</li>
                <li>Комиссия за торговлю токенами mDAO на внутренней бирже - 0.1% с каждой валюты в паре</li>
            </ul>
        </Typography>
    </>
}

export default Whitepapper
