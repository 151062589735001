import React from 'react'
import {Typography} from '@mui/material'

const About = () => {
    return <>
        <Typography variant="h3">
            DAO Dao
        </Typography>
        <Typography>
            В основе DAO Dao лежит смарт контракт на блокчейне WAX для создания микроDAO (mDAO). Каждое такое mDAO может
            выпускать долевые и управленческие токены, проводить голосования и распоряжаться накопившимися средствами.
        </Typography>
    </>
}

export default About
