export const themeOptions = {
    palette: {
        mode: 'dark',
    },
    typography: {
        h3: {
            marginBottom: 30,
        },
        h5: {
            paddingTop: 10,
            marginBottom: 10,
        },
        body1: {
            marginBottom: 15,
        },
        body2: {
            marginBottom: 15,
        },
    },
}
