import React from 'react'
import {Typography, List, ListItem, ListItemAvatar, ListItemText} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const Roadmap = () => {
    return <>
        <Typography variant={'h3'}>Roadmap</Typography>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'success'}/>
                </ListItemAvatar>
                <ListItemText primary="Site for DAO Dao with whitepapper and roadmap" secondary="March, 2023"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'primary'}/>
                </ListItemAvatar>
                <ListItemText primary="Contract with DAO's tokens" secondary="Now"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Contract for trading DAO's tokens" secondary="Soon"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Interface for trading DAO's tokens" secondary="Soon"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Sale of DAO's tokens" secondary="Soon"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Contract and interface for creating mDAOs" secondary="Later"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Contract and interface for trading mDAO's tokens" secondary="Later"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Contract and interface for voting in mDAOs" secondary="Later"/>
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <CheckCircleOutlineIcon fontSize={'large'} color={'disabled'}/>
                </ListItemAvatar>
                <ListItemText primary="Release V1" secondary="Later"/>
            </ListItem>
        </List>
    </>
}

export default Roadmap
