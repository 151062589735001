import React from 'react'
import {NavLink} from 'react-router-dom'
import {Container, Grid, Link, Typography} from '@mui/material'

const footers = [
    {
        title: 'Documents',
        description: [
            {title: 'Whitepapper', link: '/whitepapper'},
            {title: 'Roadmap', link: '/roadmap'},
        ],
    },
]

const Footer = () => {
    return <Container
        maxWidth="md"
        component="footer"
        sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            mt: 8,
            py: [3, 6],
        }}
    >
        <Grid container spacing={4} justifyContent="space-evenly">
            {footers.map((footer) => (
                <Grid item xs={6} sm={3} key={footer.title}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                        {footer.title}
                    </Typography>
                    <ul>
                        {footer.description.map((item) => (
                            <li key={item.title}>
                                <Link component={NavLink} to={item.link} variant="subtitle1" color="text.secondary">
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Grid>
            ))}
        </Grid>
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" component={NavLink} to={'/'}>
                DAO Dao
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    </Container>
}

export default Footer
